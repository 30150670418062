import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Services from './components/Services';
import './App.css';
import MasonryLayout from './components/MasonaryLayout';
import Hero from './components/Hero';
import Footer from './components/Footer';

function App() {



  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <MasonryLayout />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
