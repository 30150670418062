import React, { useState } from 'react';
import { functions } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import SubmitButton from './Button';
import './ContactModal.css'

const ContactModal = ({ isOpen, onClose }) => {
  // Controlled input state for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendContactEmail = httpsCallable(functions, 'sendContactEmail');
  
    try {
      const result = await sendContactEmail(formData);
      console.log('Email sent successfully:', result.data);
      onClose(); // Close the modal after submission
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Get in Touch</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            margin="normal"
            label="Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            margin="normal"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            margin="normal"
            label="Message"
            name="message"
            fullWidth
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            required
          />
        </DialogContent>
        <DialogActions sx={{marginRight : '15px', marginBottom : '20px'}}>
          <button label={"Cancel"} onClick={onClose}>Cancel</button>
          <button label={"Send Message"} onClick={handleSubmit}>Send Message</button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactModal;
