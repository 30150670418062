import React, {useState} from 'react';
import '../App.css'; // Importing CSS for styling
import Button from './Button';
import ContactModal from './ContactForm';
<ContactModal />

const Hero = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <header className="hero" id="home">
      <div className="hero-overlay">
        <p>Capturing what matters most to you, whether it’s life’s biggest moments or the details that define your brand.<br />From weddings and family portraits to dynamic social media content, I'll create visuals that tell your unique story.</p>
        <Button className="btn-primary" label={"Get In Touch"} onClick={openModal}/>

      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </header>
  );
};

export default Hero;
