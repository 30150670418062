import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p>&copy; {new Date().getFullYear()} JMCBURNIE PHOTOGRAPHY. All rights reserved.</p>
        <a
          href="https://www.instagram.com/jmcburnie_photography/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Follow me on Instagram
        </a>
      </div>
    </footer>
  );
};

export default Footer;
