import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import '../App.css';

// Function to dynamically import all images from a folder
const importAll = (r) => r.keys().map(r);

const MasonryLayout = () => {
  const [category, setCategory] = useState('wedding'); // Default category is "Wedding"
  const [imageLoadError, setImageLoadError] = useState({}); // Track image load errors

  // Dynamically import images based on the selected category
  const getImages = (category) => {
    switch (category) {
      case 'wedding':
        return importAll(require.context('../images/portfolio/wedding', false, /\.(png|jpe?g|svg)$/));
      case 'commercial':
        return importAll(require.context('../images/portfolio/commercial', false, /\.(png|jpe?g|svg)$/));
      case 'other':
        return importAll(require.context('../images/portfolio/landscape', false, /\.(png|jpe?g|svg)$/));
      default:
        return [];
    }
  };

  const images = getImages(category);

  const breakpointColumnsObj = {
    default: 6,
    1100: 2,
    700: 1,
  };

  // Handler for when an image fails to load
  const handleImageError = (index) => {
    setImageLoadError((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  return (
    <section className="portfolio" id="gallery">
      {/* Category Selector */}
      <div className="category-selector">
        <button
          className={`category-button ${category === 'wedding' ? 'active' : ''}`}
          onClick={() => setCategory('wedding')}
        >
          Wedding
        </button>
        <button
          className={`category-button ${category === 'commercial' ? 'active' : ''}`}
          onClick={() => setCategory('commercial')}
        >
          Commercial
        </button>
        <button
          className={`category-button ${category === 'other' ? 'active' : ''}`}
          onClick={() => setCategory('other')}
        >
          Other
        </button>
      </div>

      {/* Masonry Grid */}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          !imageLoadError[index] && ( // Only show images without load errors
            <div key={index} className="masonry-item">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                onError={() => handleImageError(index)} // Handle image load error
              />
            </div>
          )
        ))}
      </Masonry>
    </section>
  );
};

export default MasonryLayout;
