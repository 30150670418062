import React from 'react';
import './Button.css'; // CSS styles for the button

const Button = ({ label, onClick }) => {
  return (
<button className="btn-outline" onClick={onClick}>      {label}
    </button>
  );
};

const SubmitButton = ({ label, onClick }) => {
  return (
<button className="submit-btn" onClick={onClick}>      {label}
    </button>
  );
};

export default Button;
