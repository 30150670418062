import React from 'react';
import '../App.css'; // Importing CSS for styling

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-container">
        <div className="about-text">
          <h2>About Me</h2>
          <p>Versatile photographer based in Oxfordshire with a background that spans across art, illustration, graphic design, and software & web development. Having studied and worked in these fields, I’ve developed a strong foundation in both creativity and technical precision, allowing me to bring unique insights to every project.</p>
          < br/>
          <p>One of life’s great joys for me is the process of capturing an image and transforming it into something truly special through my skills. Whether it's helping a small business create impactful digital content or working on larger platforms, my passion has always been to use my creative abilities to make a difference.
						During the COVID pandemic, I offered my services to small businesses, creating digital content for free to help them with selling online. That experience reinforced my belief in the power of visuals and storytelling in building connections and driving success. Now, I’m following my passion for photography, combining my expertise in both creative and digital realms to offer the best value and results for my clients.
						I’d love to work with you to capture those moments, products, or stories that matter most, whether for personal memories or business growth.</p>
        </div>
        <div className="about-image">
          <img src="../images/portfolio/wedding/profile.jpg" alt="JMCBURNIE" />
        </div>
      </div>
    </section>
  );
};

export default About;
