import React, { useState } from 'react';
import '../App.css';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <header className="header">
      <div className="header-container">
        {/* Logo Section */}
        <div className="logo">
          <img src="images/logo1.png" alt="Logo" />
        </div>

        {/* Burger Menu for small screens */}
        <div className="burger-menu" onClick={toggleMenu}>
          ☰
        </div>

        {/* Navigation Links */}
        <nav className="nav">
          <ul className={isMenuOpen ? 'show' : ''}>
            <li href="#home" onClick={(e) => scrollToSection(e, 'home')}><a>Home</a></li>
            <li href="#about" onClick={(e) => scrollToSection(e, 'about')}><a>About</a></li>
            <li href="#gallery" onClick={(e) => scrollToSection(e, 'gallery')}><a>Gallery</a></li>
            <li href="#services" onClick={(e) => scrollToSection(e, 'services')}><a>Services</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
