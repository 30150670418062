// firebase.js
import { initializeApp, getApps  } from 'firebase/app';
import { getFunctions } from 'firebase/functions';

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyAvo8MAACg9stWwJXG4udy1w-3caAgf2Kc",
    authDomain: "jmburnie-website.firebaseapp.com",
    projectId: "jmburnie-website",
    storageBucket: "jmburnie-website.appspot.com",
    messagingSenderId: "891672950821",
    appId: "1:891672950821:web:722594c0ee624de4bf27cc"
  };

  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export functions if needed
const functions = getFunctions(app);
export { functions };